import React, { useState, useRef, useEffect } from 'react';
import logo from '../../img/logo.svg';
import MoneyForward from '../../img/moneyforward.png';
import Ovice from '../../img/ovice.png';
import Carely from '../../img/carely.png';
import GoogleCalender from '../../img/googlecalender.png';
import Slack from '../../img/slack.png';
import Confuluence from '../../img/confluence.png';
import Emergency from '../../img/emergency_phone.png'
import Box from '../../img/box.png';


const Gnav = () => {
  const [isClicked1, setIsClicked1] = useState(false);
  const [isClicked2, setIsClicked2] = useState(false);
  const [isEmergency, clickedEmergencyButton] = useState(false);

  // 緊急連絡先ボタンのステータス管理
  const handleButtonClick1 = () => {
    setIsClicked1(!isClicked1);
  }
  // リンク集ボタンのステータス管理
  const handleButtonClick2 = () => {
    setIsClicked2(!isClicked2);
  }
  // 緊急連絡先ボタンのステータス管理（スマホ版）
  const handleEmergencyButton = () => {
    clickedEmergencyButton(!isEmergency);
  }

  const refEle = useRef( null );

  const handleClickDocument = useRef( null )

  useEffect( () => {
    handleClickDocument.current = (e) => {
      if( !refEle.current.contains( e.target ) ) {
        setIsClicked1( false )
        document.removeEventListener( 'click', handleClickDocument.current )
      }
    }
  }, [] );

  useEffect( () => {
    isClicked1 && document.addEventListener( 'click', handleClickDocument.current )
  }, [ isClicked1 ] )

  return (
    <nav className='nav'>
      <div className="nav--wrapper">
        <div className="logo">
          <a href="/" className="logo--link">
            <img src={logo} alt="" className="logo--img w-100" />
          </a>
        </div>
        <div className='nav--right'>
          
          <div className={isClicked1 ? "btn--emergency__wrapper clicked" : "btn--emergency__wrapper"} ref={ refEle }>
            <div
              className={isClicked1 ? "btn--emergency clicked" : "btn--emergency"}
              onClick={handleButtonClick1}
            >
            </div>
            <p
              className={isClicked1 ? "show" : ""}
            >田中寿法：080-6259-7649</p>
          </div>
          <div
            className={isClicked2 ? "btn--links clicked" : "btn--links"}
            onClick={handleButtonClick2}
          >
          </div>
        </div>
      </div>
      <div
        className={isClicked2 ? 'nav__bg clicked' : 'nav__bg'}
        onClick={handleButtonClick2}
      ></div>
      <ul
        className={isClicked2 ? 'nav--links clicked' : 'nav--links'}
      >
        <li
          className="item emergency"
          onClick={handleEmergencyButton}
        >
          <div className="left">
            <img src={ Emergency } alt="" />
            <p>緊急連絡先</p>
          </div>
          <div className="arrow"></div>
        </li>
        <li className="item">
          <a href="https://attendance.moneyforward.com/my_page" target="_blank" rel="noopener noreferrer">
          <div className="left">
            <img src={ MoneyForward } alt="" />
            <p>勤怠管理</p>
          </div>
          <div className="arrow"></div>
          </a>
        </li>
        <li className="item">
          <a href="https://expense.moneyforward.com" target="_blank" rel="noopener noreferrer">
          <div className="left">
            <img src={ MoneyForward } alt="" />
            <p>経費申請</p>
          </div>
          <div className="arrow"></div>
          </a>
        </li>
        <li className="item">
          <a href="https://app.ovice.com/ws/egao-space" target="_blank" rel="noopener noreferrer">
          <div className="left">
            <img src={ Ovice } alt="" />
            <p>oVice</p>
          </div>
          <div className="arrow"></div>
          </a>
        </li>
        <li className="item">
          <a href="https://www.carely.io/employee/home" target="_blank" rel="noopener noreferrer">
            <div className="left">
              <img src={ Carely } alt="" />
              <p>Carely</p>
            </div>
            <div className="arrow"></div>
          </a>
        </li>
        <li className="item">
          <a href="https://calendar.google.com/calendar/u/0/r/week" target="_blank" rel="noopener noreferrer">
          <div className="left">
            <img src={ GoogleCalender } alt="" />
            <p>Googleカレンダー</p>
          </div>
          <div className="arrow"></div>
          </a>
        </li>
        <li className="item">
          <a href="https://egao-inc.slack.com/" target="_blank" rel="noopener noreferrer">
          <div className="left">
            <img src={ Slack } alt="" />
            <p>Slack</p>
          </div>
          <div className="arrow"></div>
          </a>
        </li>
        <li className="item">
          <a href="https://egao-inc.atlassian.net/wiki/home" target="_blank" rel="noopener noreferrer">
          <div className="left">
            <img src={ Confuluence } alt="" />
            <p>Confuluence</p>
          </div>
          <div className="arrow"></div>
          </a>
        </li>
        <li className="item">
          <a href="https://app.box.com/folder/0" target="_blank" rel="noopener noreferrer">
          <div className="left">
            <img src={ Box } alt="" />
            <p>box</p>
          </div>
          <div className="arrow"></div>
          </a>
        </li>
      </ul>

      {/* スマホで緊急連絡を押したとき用の確認画面 */}
      <div
        className={isEmergency ? "emergency__modal open" : "emergency__modal"}
      >
        <div className="emergency__modal--inner">
          <p className='emergency__title'>緊急連絡</p>
          <p className='emergency__text'>
            田中寿法：080-6259-7649に発信します。<br />
            本当によろしいですか？
          </p>
          <div className='emergency--bottom'>
            <a href="tel:080-6259-7649">電話しちゃう</a>
            <div
              onClick={handleEmergencyButton}
            >
              やめとこうかな
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Gnav