import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import gao from "../img/gao.svg";
import axios from 'axios';
import visible from "../img/login/visible.png";
import invisible from "../img/login/invisible.png";

const baseURL = process.env.REACT_APP_API_URL;

function LoginPage() {

  const initialValues = { mailAddress: "", password: ""};
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(["user", "token"]);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [isFailed, setIsFailed] = useState(false);

  const handleChangeVisible = () => {
    setIsVisible(!isVisible);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post(
          // API Gateway のエンドポイントURLを指定
          baseURL,
          {
            email: formValues.mailAddress,
            password: formValues.password,
          }
        );

        const status = response.data.statusCode;

        // 以下は、API のレスポンスに応じて適切な処理を追加します
        if (status === 200) {
          setCookie("token", response.data.body, { maxAge : 1800 });
          setTimeout(() => {
            navigate("/");
          }, 300);
        } else if(status === 401) {
          // エラーハンドリング
          setIsFailed(true);
        }
      } catch (error) {
        console.error("There was a problem with the axios request:", error);
        // エラーハンドリング
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if( !values.mailAddress ) {
      errors.mailAddress = "メールアドレスを入力してください";
    } else if ( !regex.test(values.mailAddress) ) {
      errors.mailAddress = "正しいメールアドレスを入力してください";
    }
    if( !values.password ) {
      errors.password = "パスワードを入力してください";
    }
    return errors;
  }

  return (
    <div className="form__container">
      <div className="form__title" aria-label="イーガオポータルへようこそ"></div>
      <div className="form__gao">
        <img src={ gao } alt="" />
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="form__ui">
          <div className="form__field">
            {isFailed && <p className="error">ログインに失敗しました。</p>}
            <div className="email">
              <label>メールアドレス</label>
              <div className="input__wrapper">
                <input
                  type="text" 
                  placeholder="example@egao-inc.co.jp" 
                  name="mailAddress" 
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {formErrors.mailAddress && <p className="error">{formErrors.mailAddress}</p>}
            </div>
            <div className="password">
              <label>パスワード</label>
              <div className="input__wrapper">
                <input
                  type={ isVisible ? "password" : "text" }
                  placeholder="Password1234" 
                  name="password" 
                  onChange={(e) => handleChange(e)}
                />
                <div
                  className="eye"
                  onClick={handleChangeVisible}
                >
                  <img src={ isVisible ? invisible : visible } alt="" />
                </div>
              </div>
              {formErrors.password && <p className="error">{formErrors.password}</p>}
            </div>
          </div>
          <button className="btn__login">ログイン</button>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;