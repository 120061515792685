import React from 'react';
import GaoBall from "../../img/gao-ball.png";
import Ground from "../../img/footer/ground.png";


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__ground">
        <img src={Ground} alt="" />
      </div>
      <div className="footer__grass"></div>
      <div className="footer__gao-ball">
        <a href="https://project-eeeeex.com/" target="_blank" rel="noopener noreferrer">
          <img src={GaoBall} alt="" />
        </a>
      </div>
    </footer>
  )
}

export default Footer