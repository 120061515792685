import React, {useEffect, useState} from 'react'
import axios from 'axios';
import megaphone from '../../img/megaphone.png';
import ReactPaginate from 'react-paginate';
import { CurrentDate } from '../common/GetDate';

const NoticeFull = () => {
  const dataUrl = String(process.env.REACT_APP_SPREADSHEET_URL);
  const today = CurrentDate();
  console.log("test");

  // おしらせの型宣言
  type Notice = {
    id: string;
    category: string;
    category_class: string;
    title: string;
    post_date: string;
    deadline?: string;
    link: string;
  }

  const [notices, setNotices] = useState<Notice[]>([]);

  // スプレッドシートから取得したデータを、「key: value」の型に修正する
  const formatData = ({data}) => {
    const keys = data.values[0];
    const _data = data.values.slice(1);
    const obj = _data.map(arr => Object.assign({}, ...keys.map((k, i) => ({ [k]: arr[i] }))));
    return obj
  }

  // 初回ロード時にスプレッドシートからおしらせデータを取得
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await axios.get(dataUrl);
        const { data } = res;
        const formattedData = formatData({data})
        // おしらせを最新順に並び替える
        const reversedData = [...formattedData].reverse()
        // noticesにデータを入力
        setNotices(reversedData);
      } catch(e) {
        console.log('fetch data error', e)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const noticeCount = notices.length;

  const [itemsOffset, setItemsOffset] = useState(0);

  const itemsPerPage = 10;

  const endOffset = itemsOffset + itemsPerPage;

  const currentNotices = notices.slice(itemsOffset, endOffset);
  const pageCount = Math.ceil(noticeCount / itemsPerPage);

  const handlePageClick = (e: { selected: number }) => {
    const newOffset = (e.selected * itemsPerPage) % notices.length;
    setItemsOffset(newOffset);
    window.scrollTo({
      top: 0,
    })
  };

  return (
    <section className='notice full'>
      <div className="notice__title full">
        <div className='left'>
          <img src={megaphone} alt="" />
          <h2>おしらせ一覧</h2>
        </div>
        <div className='notice__page'><span>{endOffset / 10}</span>ページ目</div>
      </div>
      <ul className="notice__list">
        {currentNotices.map((notice: Notice, index: number) => (
          <li key={index} className='notice__item'>
            <a href={'/notice/' + notice.id} className='notice__item--wrapper'>
              <div className="notice__main-contents">
                <div className="notice__category">
                  <div className={`notice__category--label ${notice.category_class}`}>{notice.category}</div>
                </div>
                <p className='notice__text'>{notice.title}</p>
                <div className="notice__item--bottom">
                  <time className='notice__item--bottom__post-time' dateTime={notice.post_date}>{notice.post_date}</time>
                  <div className="notice__item--right">
                    {notice.deadline ? <p className={notice.deadline > today ? 'notice__item--right__deadline' : 'notice__item--right__deadline expired'}>{notice.deadline > today ? notice.deadline + "まで" : "期限切れ"}</p> : <p className='notice__item--right__deadline none'>期限なし</p>}
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <ReactPaginate
          marginPagesDisplayed={2} //先頭と末尾に表示するページの数。今回は2としたので1,2…今いるページの前後…後ろから2番目, 1番目 のように表示されます。
          pageRangeDisplayed={5} //上記の「今いるページの前後」の番号をいくつ表示させるかを決めます。
          containerClassName='pagination' //ページネーションリンクの親要素のクラス名
          pageClassName='pagination__item' //各子要素(li要素)のクラス名
          pageLinkClassName='pagination__link' //ページネーションのリンクのクラス名
          activeClassName='active' //今いるページ番号のクラス名。今いるページの番号だけ太字にしたりできます 
          previousLabel='<' //前のページ番号に戻すリンクのテキスト
          nextLabel='>' //次のページに進むボタンのテキスト
          previousClassName='pagination__item pagination__item--prev' // '<'の親要素(li)のクラス名
          nextClassName='pagination__item pagination__item--next' //'>'の親要素(li)のクラス名
          previousLinkClassName='pagination__link'  //'<'のリンクのクラス名
          nextLinkClassName='pagination__link' //'>'のリンクのクラス名
          disabledClassName='disabled' //先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくするためのクラス
          breakLabel='...' // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
          breakClassName='pagination__item' // 上記の「…」のクラス名
          breakLinkClassName='pagination__link' // 「…」の中のリンクにつけるクラス
          pageCount={pageCount}
          onPageChange={handlePageClick}
      />
      <div className="btn__notice back">
        <a href="/">
          <p>トップへ戻る</p>
        </a>
      </div>
    </section>
  )
}

export default NoticeFull;