import Gnav from "../components/common/Gnav.jsx";
import NoticeContent from "../components/notice/NoticeContent.tsx";
import React from 'react'

const NoticePage = () => {
  return (
    <div className="main__wrapper">
      <Gnav />
      <NoticeContent />
    </div>
  )
}

export default NoticePage