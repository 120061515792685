import Gnav from "../components/common/Gnav.jsx";
import TopSlider from "../components/top/TopSlider.tsx";
import Notice from "../components/top/Notice.tsx";
import Category from "../components/top/Category.tsx";
import Footer from "../components/common/Footer.jsx";
import profGao from '../img/gao-prof.png';

function TopPage() {
  return (
    <div className="main__wrapper">
      <Gnav />
      <TopSlider />
      <div className="border"></div>
      <Notice />
      <div className="notice--gao">
        <a href="http://egao-prof.com/gaogourmand" target="_blank" rel="noopener noreferrer">
          <img src={profGao} alt="" />
        </a>
      </div>
      <div className="border"></div>
      <Category />
      <Footer />
    </div>
  );
}

export default TopPage;