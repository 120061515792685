import React, {useState} from 'react';
import icon from "../../img/category-icon.png";
import externalLink from '../../img/external-link.png';
import AnchorLink from "react-anchor-link-smooth-scroll";
// まだDBがないので、一時的にファイル直置きで読み込む
import Data from '../../Data/db.json';

const Category = () => {
  const Categories = Data.Categories;

  // Categoriesの型宣言
  type Category = {
    id: string;
    title: string;
    sub_title: string;
    items: Item[];
  }
  interface Item {
    title: string;
    link: string;
  }

  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    if(activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };


  return (
    <section className='category'>
      <div className='category__title'>
        <img src={icon} alt="" />
        <h2>カテゴリからさがす</h2>
      </div>
      <ul className='category__list'>
        {Categories.map((category: Category, index: number) => (
          <li id={category.id} key={category.id} className='category__item'>
            <AnchorLink
              href={`#${category.id}`}
              offset="100"
              key={index}
              className="title"
              onClick={() => handleItemClick(index)}
            >
              <div className='left'>
                <img src={icon} alt="" />
                <div className='bottom'>
                  <h3>{ category.title }</h3>
                  <p>{ category.sub_title }</p>
                </div>
              </div>
              <div className={`${activeIndex === index ? 'arrow clicked' : 'arrow'}`}></div>
            </AnchorLink>
          <div className="box">
            <ul
              className={`${activeIndex === index ? 'show' : ''}`}
            >
              {category.items.map((item: Item, index: number) => (
                <li
                  key={`${category.id}+${index}`}
                >
                  <a href={ item.link } target='_blank' rel='noreferrer'>
                    <p>{ item.title }</p>
                    <img src={externalLink} alt="" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Category