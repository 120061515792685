import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import gao from '../../img/slider/gao.png';
import buildings from "../../img/buildings.png";
import { CurrentDate } from '../common/GetDate';

const TopSlider = () => {
  const dataUrl = String(process.env.REACT_APP_SPREADSHEET_URL_BANNER);
  const domain = String(process.env.REACT_APP_AWS_DOMAIN)
  const today = CurrentDate();

  type Banner = {
    id: string;
    period: string;
    banner_url: string;
  }

  const [banners, setBanners] = useState<Banner[]>([]);

  const formatData = ({data}) => {
    const keys = data.values[0];
    const _data = data.values.slice(1);
    const obj = _data.map(arr => Object.assign({}, ...keys.map((k, i) => ({ [k]: arr[i] }))));
    return obj
  }

  const filterDate = (data) => {
    const obj = data.filter(items => items.period > today);
    return obj
  }

  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await axios.get(dataUrl);
        const { data } = res;
        const formattedData = formatData({data})
        // バナー掲載期限内のものだけにする
        const filteredDate = filterDate(formattedData)
        // bannersにデータを入力
        setBanners(filteredDate);
      } catch(e) {
        console.log('fetch data error', e)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const settings = {
    dots: true, // ナビゲーションドットを表示するかどうか
    infinite: true, // スライドを無限にループさせるかどうか
    slidesToShow: 2.3, // 1回に表示するスライドの数
    slidesToScroll: 1, // 1回にスクロールするスライドの数
    autoplay: true, // 自動再生を有効にするかどうか
    arrows: false, // 「前」「次」のスライドを操作する矢印を表示かどうか
    autoplaySpeed: 5000, // 自動再生時のスライド切り替えの速さ（ミリ秒）
    speed: 1000, // スライドのトランジションアニメーションの速さ（ミリ秒）
    pauseOnHover: false, // ホバー時に自動再生を一時停止するかどうか
    centerMode: true,
    responsive: [
      {
        breakpoint: 3000, // 600px以下の画面幅に対する設定
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 2000, // 600px以下の画面幅に対する設定
        settings: {
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 768, // 480px以下の画面幅に対する設定
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  

  return (
    <div className = "slider__container">
      <Slider className= "SliderSection" {...settings}>
        {banners.map((banner: Banner, index: number) => (
          <a href={`/notice/${banner.id}`} key={index} className='slide-link'>
            <img className= "SlideImage" src={`${domain}banner/${banner.banner_url}`} alt={`Slide slide-${index}`}></img>
          </a>
        ))}
      </Slider>
      <div className="slider__gao">
        <div className="gao">
          <a href="https://www.egao-inc.co.jp/" target="_blank" rel="noopener noreferrer">
            <img src={ gao } alt=""/>
          </a>
        </div>
        <div className="slider__gao--bg">
          <img src={ buildings } alt=""/>
        </div>
      </div>
    </div>
  );
};

export default TopSlider;