import Gnav from "../components/common/Gnav.jsx";
import NoticeFull from "../components/notice/NoticeFull.tsx";
import React from 'react'


const NoticePage = () => {
  return (
    <div className="main__wrapper">
      <Gnav />
      <NoticeFull />
    </div>
  )
}

export default NoticePage