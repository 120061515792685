import React, {useEffect, useState} from 'react'
import axios from 'axios';
import megaphone from '../../img/megaphone.png';
import { CurrentDate } from '../common/GetDate';

const Notice = () => {
  const dataUrl = String(process.env.REACT_APP_SPREADSHEET_URL);

  const today = CurrentDate();

  // おしらせの型宣言
  type Notice = {
    id: string;
    category: string;
    category_class: string;
    title: string;
    post_date: string;
    deadline?: string;
    link: string;
    image?: string;
  }

  const [notices, setNotices] = useState<Notice[]>([]);

  // スプレッドシートから取得したデータを、「key: value」の型に修正する
  const formatData = ({data}) => {
    const keys = data.values[0];
    const _data = data.values.slice(1);
    const obj = _data.map(arr => Object.assign({}, ...keys.map((k, i) => ({ [k]: arr[i] }))));
    return obj
  }

  // 初回ロード時にスプレッドシートからおしらせデータを取得
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await axios.get(dataUrl);
        const { data } = res;
        const formattedData = formatData({data})
        // おしらせを最新順に並び替える
        const reversedData = [...formattedData].reverse()
        // noticesにデータを入力
        setNotices(reversedData);
      } catch(e) {
        console.log('fetch data error', e)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const noticeCount = notices.length;

  return (
    <section className='notice'>
      <div className="notice__title">
        <img src={megaphone} alt="" />
        <h2>おしらせ</h2>
        <div className="number">{noticeCount}</div>
      </div>
      <ul className="notice__list">
        {notices.slice(0, 3).map((notice: Notice, index: number) => (
          <li key={index} className='notice__item'>
            <a href={'/notice/' + notice.id} className='notice__item--wrapper'>
              <div className="notice__main-contents">
                <div className="notice__category">
                  <div className={`notice__category--label ${notice.category_class}`}>{notice.category}</div>
                </div>
                <p className='notice__text'>{notice.title}</p>
                <div className="notice__item--bottom">
                  <time className='notice__item--bottom__post-time' dateTime={notice.post_date}>{notice.post_date}</time>
                  <div className="notice__item--right">
                  {notice.deadline ? <p className={notice.deadline > today ? 'notice__item--right__deadline' : 'notice__item--right__deadline expired'}>{notice.deadline > today ? notice.deadline + "まで" : "期限切れ"}</p> : <p className='notice__item--right__deadline none'>期限なし</p>}
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <div className="btn__notice">
        <a href="/notice">
          <p>一覧をみる</p>
        </a>
      </div>
    </section>
  )
}

export default Notice;