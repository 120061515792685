import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { useParams } from "react-router-dom";
import { CurrentDate } from '../common/GetDate';

const NoticeContent = () => {
  const dataUrl = String(process.env.REACT_APP_SPREADSHEET_URL);
  const { id } = useParams();
  const urlPattern = "https";
  const today = CurrentDate();

  const [mainTexts, setMainTexts] = useState([]);
  const [title, setTitle] = useState();
  const [category, setCategory] = useState();
  const [categoryClass, setCategoryClass] = useState();
  const [postDate, setPostDate] = useState();
  const [deadline, setDeadline] = useState();

  // スプレッドシートから取得したデータを、「key: value」の型に修正する
  const formatData = ({data}) => {
    const keys = data.values[0];
    const _data = data.values.slice(1);
    const obj = _data.map(arr => Object.assign({}, ...keys.map((k, i) => ({ [k]: arr[i] }))));
    return obj
  }

  // 初回ロード時にスプレッドシートからおしらせデータを取得
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await axios.get(dataUrl);
        const { data } = res;
        const formattedData = formatData({data})
        // noticesにデータを入力
        const singleData = formattedData.find(data => data.id === id);
        if(singleData.main_texts) {
          setMainTexts(singleData.main_texts.split("\n"));
        }
        setTitle(singleData.title);
        setCategory(singleData.category);
        setCategoryClass(singleData.category_class);
        setPostDate(singleData.post_date);
        setDeadline(singleData.deadline);
      } catch(e) {
        console.log('fetch data error', e)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='notice notice__post'>
      <div className='notice__post--body'>
        <h1 className='notice__post--title'>{title}</h1>
        <div className="border"></div>
        <div className="notice__post--details">
          <div className="notice__category">
            <div className={`notice__category--label ${categoryClass}`}>{category}</div>
          </div>
          <div className='notice__post--time'>
            <time className='notice__item--bottom__post-time'>{postDate}</time>
            {deadline ? <p className={deadline > today ? 'notice__item--right__deadline' : 'notice__item--right__deadline expired'}>{deadline > today ? deadline + "まで" : "期限切れ"}</p> : <p className='notice__item--right__deadline none'>期限なし</p>}
          </div>
        </div>
        <div className="border"></div>
        <article className='notice__post--main-texts'>
          <div id='aaa'>
            { mainTexts.map((item, index) => (
              <p key={index}>
                {
                  item.indexOf(urlPattern) > -1 ? <a href={item} target='_blank' rel='noreferrer'>{item}</a> :
                  item ? item :
                  "\n"
                }
              </p>
            )) }
          </div>
        </article>
      </div>
      <div className="btn__notice back">
        <a href="/notice">
          <p>一覧へ戻る</p>
        </a>
      </div>
    </section>
  )
}

export default NoticeContent;