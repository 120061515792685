import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { useCookies } from "react-cookie";
import LoginPage from './pages/LoginPage';
import TopPage from './pages/TopPage';
import NoticePage from './pages/NoticePage';
import NoticeSinglePage from './pages/NoticeSinglePage';

function App() {
  const [cookies] = useCookies(["user", "token"]);
  const loggedIn = cookies.token;

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={ loggedIn ? <TopPage /> : <Navigate replace to="/login" />} />
        <Route path="/login" element={ loggedIn ? <Navigate replace to="/" /> : <LoginPage />} />
        <Route path="/notice" element={ loggedIn ? <NoticePage /> : <Navigate replace to="/login" />} />
        <Route path="/notice/:id" element={ loggedIn ? <NoticeSinglePage /> : <Navigate replace to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;